import React from 'react';
import {withStyles} from "@material-ui/core/styles";
import { ThemeProvider, createTheme } from '@mui/material/styles';
import Logo from "../../../assets/shapes.png";
import Button from "@mui/material/Button";
import BuyButton from "../../BuyButton";
import {Link} from "react-router-dom";
import { Ellipsis } from 'react-spinners-css';

const muiTheme = createTheme({
    palette: {
        primary: {
            main: "#FF2E28",
        },
        secondary: {
            main: "#FFF",
        },
    },
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    fontFamily: "montserratbold",
                    fontSize: '1rem',
                    padding: '5px 8px',
                    borderRadius: '4',
                },
            }
        }
    }
});

const useStyles = theme => ({
    panel: {
        zIndex: '999',
        width: '100%',
        minHeight: '100%',
        display: 'flex',

        [theme.breakpoints.up('md')]: {
        }
    },
    effect: {
        display: 'flex',
        height: '100%',
        width: '100%',
        alignItems: 'center',
        justifyContent: 'center',
        background: 'linear-gradient(90deg, #614F41, #181A17)',
    },
    effectLogo: {
        display: 'flex',
        height: '100%',
        width: '100%',
        alignItems: 'center',
        justifyContent: 'center',

        [theme.breakpoints.up('md')]: {
            backgroundImage: 'url(' + Logo + ')',
            backgroundRepeat: 'no-repeat',
            backgroundSize: '239px 189px',
            backgroundPosition: 'calc(50% + 491px) 50%',
        }
    },
    content: {
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        width: '100%',
        height: '100%',
        flexDirection: 'column',

        [theme.breakpoints.up('md')]: {
            width: '982px',
        }
    },
    textContent: {
        padding: '46px 18px 30px 18px',

        [theme.breakpoints.up('md')]: {
            padding: '46px 170px 60px 170px',
        }
    },
    title: {
        fontFamily: 'Divona',
        fontSize: '2.7em',
        lineHeight: '1.2em',
        color: '#FFE0BD',
    },
    subtitle: {
        fontFamily: 'montserratbold',
        letterSpacing: '0.1em',
        margin: '12px 0',
        textTransform: 'uppercase',
        color: '#FFF',
    },
    text: {
        width: '100%',
        margin: '20px 0 20px 0',
        color: '#FFF',
        textAlign: 'center',

        "& table": {
            margin: '26px auto 26px auto',
            borderSpacing: '0',

            [theme.breakpoints.up('lg')]: {
                width: '580px',
            },
        },

        "& tbody tr:nth-child(odd)": {
            background: 'rgba(13, 13, 13, 0.2)',
        },

        "& th": {
            textAlign: 'left',
            fontFamily: 'montserratbold',
            letterSpacing: '0.1em',
            textTransform: 'uppercase',
            color: '#FFF',
            padding: '15px 8px 15px 8px',

            [theme.breakpoints.up('md')]: {
                padding: '15px 20px 15px 20px',
            }
        },

        "& td": {
            textAlign: 'left',
            padding: '15px 8px 15px 8px',

            [theme.breakpoints.up('md')]: {
                padding: '15px 20px 15px 20px',
            },
        }
    },
    btn: {
        margin: '26px 0 26px 0',
    },
    mobile: {
        display: 'block',

        [theme.breakpoints.up('md')]: {
            display: 'none',
        },
    },
    desktop: {
        display: 'none',

        [theme.breakpoints.up('md')]: {
            display: 'block',
        },
    },
    logo: {
        width: '112px',
    },
    loaderContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    }
});

class MembershipBuyPanel extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            jsonProducts: []
        }
    }

    componentDidMount() {
//        fetch('http://192.168.1.169:3456/projects/Trailburning/tb-adelaidewingchunacademy/server/membership.php')
        fetch('https://www.adelaidewingchunacademy.com.au/server/membership.php')
            .then(response => response.json())
            .then(data => {
//                console.log(data);
                this.setState({jsonProducts: data});
            })
    }

    render() {
        const { classes } = this.props;
        let self = this;


        function RenderMembership() {
            if (self.state.jsonProducts.length) {
                return (
                    <table>
                        <thead>
                        <tr>
                            <th></th>
                            <th>Fortnightly</th>
                            <th>Monthly</th>
                        </tr>
                        </thead>
                        <tbody>
                        {self.state.jsonProducts.map(item => {
                            return (
                                <tr key={item.id}>
                                    <th><span className={classes.mobile}>{item.description}</span><span className={classes.desktop}>{item.description}</span></th>

                                    { item.prices.map(price => {
                                            if(price.interval == 'week' && price.interval_count == 2)
                                                return <td key={price.id}><a href={price.url}><Button variant="outlined" color="secondary">Buy&nbsp;${price.display_price}</Button></a></td>
                                            return null
                                        }
                                    )}

                                    { item.prices.map(price => {
                                            if(price.interval == 'month')
                                                return <td key={price.id}><a href={price.url}><Button variant="outlined" color="secondary">Buy&nbsp;${price.display_price}</Button></a></td>
                                            return null
                                        }
                                    )}
                                </tr>
                            )
                        })}
                        </tbody>
                    </table>
                    /*
                    <table>
                        <thead>
                        <tr>
                            <th></th>
                            <th>Monthly</th>
                            <th>Fort&shy;nightly</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <th><span className={classes.mobile}>Once per week</span><span className={classes.desktop}>Once per week</span></th>
                            <td><BuyButton price={66} title={'Once_Per_Week'} freq={4} /></td>
                            <td><BuyButton price={33} title={'Once_Per_Week'} freq={2} /></td>
                        </tr>
                        <tr>
                            <th><span className={classes.mobile}>Unlimited*</span><span className={classes.desktop}>Unlimited*</span></th>
                            <td><BuyButton price={94} title={'Unlimited'} freq={4} /></td>
                            <td><BuyButton price={47} title={'Unlimited'} freq={2} /></td>
                        </tr>
                        <tr>
                            <th><span className={classes.mobile}>Concessional unlimited*</span><span className={classes.desktop}>Concessional unlimited*</span></th>
                            <td><BuyButton price={84} title={'Concessional_Unlimited'} freq={4} /></td>
                            <td><BuyButton price={42} title={'Concessional_Unlimited'} freq={2} /></td>
                        </tr>
                        </tbody>
                    </table>
                    */
                )
            }

            return (
                <div className={classes.loaderContainer}><Ellipsis color="#FF2E28"/></div>
            )
        }

        return (
            <ThemeProvider theme={muiTheme}>

            <div className={classes.panel}>
                <div className={classes.effect}>
                    <div className={classes.effectLogo}>

                    <div className={classes.content}>
                        <div className={classes.textContent}>
                            <div className={classes.title}>Choose Your Package</div>
                            <div className={classes.text}>
                                Our membership packages include annual membership and insurance and offer great value for all our students.  Come and see us to discuss which package would work best for you.

                                <RenderMembership />

                                <strong>Voucher Lesson book available</strong> - 10 x lesson vouchers $198 (valid 12 months from date of purchase)

                                <div className={classes.btn}><Link to="/freelesson"><Button variant="contained">Book my free lesson</Button></Link></div>

                                * All adults classes
                            </div>

                        </div>
                    </div>

                    </div>
                </div>
            </div>

            </ThemeProvider>
        )
    }
}

export default withStyles(useStyles)(MembershipBuyPanel)