import React from 'react';
import {withStyles} from "@material-ui/core/styles";
import { ThemeProvider, createTheme } from '@mui/material/styles';
import HeroImage from "../../../assets/instructors/sifus/24WingChunAcademyLR-272.jpg";
import {Link} from "react-router-dom";

const muiTheme = createTheme({
    palette: {
        primary: {
            main: "#FF2E28",
        },
        secondary: {
            main: "#FFF",
        },
    },
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    fontFamily: "montserratbold",
                    fontSize: '1rem',
                    padding: '10px 28px',
                    borderRadius: '4',
                },
            }
        },
    }
});

const useStyles = theme => ({
    panel: {
        zIndex: '999',
        backgroundColor: '#0D0D0D',
        width: '100%',
        height: '100%',
        minHeight: '100%',
        display: 'flex',
    },
    effect: {
        position: 'relative',
        display: 'flex',
        height: '100%',
        width: '100%',
        alignItems: 'center',
        justifyContent: 'center',
        background: 'linear-gradient(90deg, #614F41, #181A17)',
    },
    panelContent: {
        position: 'relative',
        display: 'flex',
        height: '100%',
        width: '100%',
        alignItems: 'center',
        justifyContent: 'center',
    },
    content: {
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        textAlign: 'center',

        [theme.breakpoints.up('md')]: {
            width: '862px',
        },

        [theme.breakpoints.up('lg')]: {
            flexDirection: 'row',
            width: '1082px',
        }
    },
    photoContent: {
        display: 'flex',
        justifyContent: 'center',
        padding: '48px 36px 0px 36px',

        [theme.breakpoints.up('md')]: {
            padding: '76px 46px 0px 46px',
        },

        [theme.breakpoints.up('lg')]: {
            padding: '76px 0px 64px 50px',
        },
    },
    photo: {
        width: '100%',

        '& img': {
            width: '100%',
        },

        [theme.breakpoints.up('lg')]: {
            width: '482px',
        },
    },
    textContent: {
        textAlign: 'left',
        padding: '16px 16px 26px 16px',

        [theme.breakpoints.up('md')]: {
            padding: '46px 0px 46px 0px',
        },

        [theme.breakpoints.up('lg')]: {
            padding: '64px 0px 64px 66px',
        },
    },
    subtitle: {
        fontFamily: 'montserratbold',
        letterSpacing: '0.1em',
        margin: '12px 0',
        textTransform: 'uppercase',
        color: '#FFF',
    },
    text: {
        margin: '0 0 20px 0',
        color: '#FFF',
    },
    more: {
        margin: '12px 0',
        color: '#FFF',

        '& a': {
            padding: '2px 4px',
            fontFamily: 'montserratbold',
            letterSpacing: '0.1em',
            textTransform: 'uppercase',
            color: '#FFF',
            textDecoration: 'none',
            background: 'linear-gradient(0, #614F41 50%, transparent 50%)',
        },
        '& a:hover': {
            background: 'none',
        }
    },
});

class SifusPanel extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
        }
    }

    componentDidMount() {
    }

    render() {
        const { classes } = this.props;
        let self = this;

        return (
            <ThemeProvider theme={muiTheme}>

            <div className={classes.panel}>
                <div className={classes.effect}>

                    <div className={classes.panelContent}>

                    <div className={classes.content}>
                        <div className={classes.photoContent}>
                            <div className={classes.photo}><img src={HeroImage} /></div>
                        </div>

                        <div className={classes.textContent}>
                            <div className={classes.subtitle}>Our Sifus have been on very different paths with their Wing Chun journeys, paths which bring different but complimentary teaching to the academy.</div>
                            <div className={classes.text}>
                                 Assisted by our instructors who also hold qualifications in Sports Coaching, First Aid and Group Training, offering classes specifically for adults ands kids.
                            </div>

                            <div className={classes.more}><Link to="/sifutony">Sifu Tony Psaila</Link>&nbsp;&nbsp;|&nbsp;&nbsp;<Link to="/sifudamian">Sifu Damian Rainer</Link></div>
                        </div>
                    </div>

                    </div>
                </div>
            </div>

            </ThemeProvider>
        )
    }
}

export default withStyles(useStyles)(SifusPanel)