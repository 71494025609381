import React from 'react';
import {withStyles} from "@material-ui/core/styles";
import { ThemeProvider, createTheme } from '@mui/material/styles';
import Logo from "../../../assets/shapes.png";
import Button from "@mui/material/Button";
import {Link} from "react-router-dom";

const muiTheme = createTheme({
    palette: {
        primary: {
            main: "#FF2E28",
        },
        secondary: {
            main: "#FFF",
        },
    },
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    fontFamily: "montserratbold",
                    fontSize: '1rem',
                    padding: '5px 8px',
                    borderRadius: '4',
                },
            }
        }
    }
});

const useStyles = theme => ({
    panel: {
        zIndex: '999',
        width: '100%',
        minHeight: '100%',
        display: 'flex',

        [theme.breakpoints.up('md')]: {
        }
    },
    effect: {
        display: 'flex',
        height: '100%',
        width: '100%',
        alignItems: 'center',
        justifyContent: 'center',
        background: 'linear-gradient(90deg, #614F41, #181A17)',
    },
    effectLogo: {
        display: 'flex',
        height: '100%',
        width: '100%',
        alignItems: 'center',
        justifyContent: 'center',

        [theme.breakpoints.up('md')]: {
            backgroundImage: 'url(' + Logo + ')',
            backgroundRepeat: 'no-repeat',
            backgroundSize: '239px 189px',
            backgroundPosition: 'calc(50% + 491px) 50%',
        }
    },
    content: {
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        width: '100%',
        height: '100%',
        flexDirection: 'column',

        [theme.breakpoints.up('md')]: {
            width: '982px',
        }
    },
    textContent: {
        padding: '46px 18px 30px 18px',

        [theme.breakpoints.up('md')]: {
            padding: '46px 170px 60px 170px',
        }
    },
    title: {
        fontFamily: 'Divona',
        fontSize: '2.7em',
        lineHeight: '1.2em',
        color: '#FFE0BD',
    },
    subtitle: {
        fontFamily: 'montserratbold',
        letterSpacing: '0.1em',
        margin: '12px 0',
        textTransform: 'uppercase',
        color: '#FFF',
    },
    text: {
        width: '100%',
        margin: '20px 0 20px 0',
        color: '#FFF',
        textAlign: 'center',

        "& table": {
            margin: '26px auto 26px auto',
            borderSpacing: '0',

            [theme.breakpoints.up('lg')]: {
                width: '580px',
            },
        },

        "& tbody tr:nth-child(odd)": {
            background: 'rgba(13, 13, 13, 0.2)',
        },

        "& th": {
            textAlign: 'left',
            fontFamily: 'montserratbold',
            letterSpacing: '0.1em',
            textTransform: 'uppercase',
            color: '#FFF',
            padding: '15px 8px 15px 8px',

            [theme.breakpoints.up('md')]: {
                padding: '15px 20px 15px 20px',
            }
        },

        "& td": {
            textAlign: 'left',
            padding: '15px 8px 15px 8px',

            [theme.breakpoints.up('md')]: {
                padding: '15px 20px 15px 20px',
            },
        }
    },
    btn: {
        margin: '26px 0 26px 0',
    },
    mobile: {
        display: 'block',

        [theme.breakpoints.up('md')]: {
            display: 'none',
        },
    },
    desktop: {
        display: 'none',

        [theme.breakpoints.up('md')]: {
            display: 'block',
        },
    },
    logo: {
        width: '112px',
    }
});

class OfferBuyPanel extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
        }
    }

    componentDidMount() {
    }

    render() {
        const { classes } = this.props;
        let self = this;

        return (
            <ThemeProvider theme={muiTheme}>

            <div className={classes.panel}>
                <div className={classes.effect}>
                    <div className={classes.effectLogo}>

                    <div className={classes.content}>
                        <div className={classes.textContent}>
                            <div className={classes.title}>Claim the Offer</div>
                            <div className={classes.text}>
                                We have made it super simple for you to claim our offer, you can purchase it right here, right now!  If you're not quite ready, no problem, you can try before you buy and book a free lesson, or drop us a line for more details.

                                <table>
                                    <thead>
                                    <tr>
                                        <th></th>
                                        <th>Offer</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr>
                                        <th>I'm ready!</th>
                                        <td><a href="https://buy.stripe.com/fZecQ76Lea3fem49AG"><Button variant="contained">Buy&nbsp;$49.95</Button></a></td>
                                    </tr>
                                    <tr>
                                        <th>First I'd like to come and try</th>
                                        <td><Link to="/freelesson"><Button variant="outlined" color="secondary">Free&nbsp;Lesson</Button></Link></td>
                                    </tr>
                                    <tr>
                                        <th>I'd like some more information</th>
                                        <td><Link to="/contact"><Button variant="outlined" color="secondary">Contact&nbsp;Us</Button></Link></td>
                                    </tr>
                                    </tbody>
                                </table>

                            </div>

                        </div>
                    </div>

                    </div>
                </div>
            </div>

            </ThemeProvider>
        )
    }
}

export default withStyles(useStyles)(OfferBuyPanel)