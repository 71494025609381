import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import HeaderPanel from './../../HeaderPanel';
import FooterPanel from './../../FooterPanel';
import ClassesHeroPanel from "./ClassesHeroPanel";
import ClassesPanel from "./ClassesPanel";
import MapPanel from "./../../MapPanel";
import ClassesFeesPanel from "./ClassesFeesPanel";
import DragonsPanel from "./../../DragonsPanel";

const useStyles = theme => ({
    branding: {
        display: 'flex',
        flexWrap: 'wrap',
    },
});

class ClassesPage extends React.Component {
  myRef = React.createRef();

  constructor(props) {
    super(props);
    this.state = {
    };
  }

  componentDidMount() {
    let self = this;
  }

  componentWillUnmount() {
  }

  render() {
    const { classes } = this.props;

    let self = this;

      return (
          <div className={classes.branding}>
              <HeaderPanel showLogo={true} ref={this.myRef} page={'classes'} />
              <ClassesHeroPanel />
              <ClassesPanel />
              <MapPanel />
              <ClassesFeesPanel />
              <DragonsPanel />
              <FooterPanel />
          </div>
      );
  }
}

export default withStyles(useStyles)(ClassesPage)
