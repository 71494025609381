import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import HeaderPanel from './../../HeaderPanel';
import FooterPanel from './../../FooterPanel';
import SifuDamianHeroPanel from "./SifuDamianHeroPanel";
import SifuDamianPanel from "./SifuDamianPanel";
import MapPanel from "./../../MapPanel";

const useStyles = theme => ({
    branding: {
        display: 'flex',
        flexWrap: 'wrap',
    },
});

class SifuDamianPage extends React.Component {
  myRef = React.createRef();

  constructor(props) {
    super(props);
    this.state = {
    };
  }

  componentDidMount() {
    let self = this;
  }

  componentWillUnmount() {
  }

  render() {
    const { classes } = this.props;

    let self = this;

      return (
          <div className={classes.branding}>
              <HeaderPanel showLogo={true} ref={this.myRef} page={'instructors'} />
              <SifuDamianHeroPanel />
              <SifuDamianPanel />
              <MapPanel />
              <FooterPanel />
          </div>
      );
  }
}

export default withStyles(useStyles)(SifuDamianPage)
