import React from 'react';
import {withStyles} from "@material-ui/core/styles";
import { ThemeProvider, createTheme } from '@mui/material/styles';
import Logo from "../../../assets/shapes.png";
import Button from "@mui/material/Button";
import {Link} from "react-router-dom";

const muiTheme = createTheme({
    palette: {
        primary: {
            main: "#FF2E28",
        },
        secondary: {
            main: "#FFF",
        },
    },
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    fontFamily: "montserratbold",
                    fontSize: '1rem',
                    padding: '10px 16px',
                    borderRadius: '4',
                },
            }
        }
    }
});

const useStyles = theme => ({
    panel: {
        zIndex: '999',
        width: '100%',
        minHeight: '100%',
        display: 'flex',

        [theme.breakpoints.up('md')]: {
        }
    },
    effect: {
        display: 'flex',
        height: '100%',
        width: '100%',
        alignItems: 'center',
        justifyContent: 'center',
        background: 'linear-gradient(90deg, #614F41, #181A17)',
    },
    effectLogo: {
        display: 'flex',
        height: '100%',
        width: '100%',
        alignItems: 'center',
        justifyContent: 'center',

        [theme.breakpoints.up('md')]: {
            backgroundImage: 'url(' + Logo + ')',
            backgroundRepeat: 'no-repeat',
            backgroundSize: '239px 189px',
            backgroundPosition: 'calc(50% + 491px) 50%',
        }
    },
    content: {
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        width: '100%',
        height: '100%',
        flexDirection: 'column',

        [theme.breakpoints.up('md')]: {
            width: '982px',
        }
    },
    textContent: {
        padding: '46px 18px 30px 18px',

        [theme.breakpoints.up('md')]: {
            padding: '46px 170px 60px 170px',
        }
    },
    title: {
        fontFamily: 'Divona',
        fontSize: '2.7em',
        lineHeight: '1.2em',
        color: '#FFE0BD',
    },
    subtitle: {
        fontFamily: 'montserratbold',
        letterSpacing: '0.1em',
        margin: '12px 0',
        textTransform: 'uppercase',
        color: '#FFF',
    },
    text: {
        width: '100%',
        margin: '20px 0 20px 0',
        color: '#FFF',
        textAlign: 'center',

        "& table": {
            margin: '26px auto 26px auto',
            borderSpacing: '0',

            [theme.breakpoints.up('lg')]: {
                width: '580px',
            },
        },

        "& tbody tr:nth-child(odd)": {
            background: 'rgba(13, 13, 13, 0.2)',
        },

        "& th": {
            textAlign: 'left',
            fontFamily: 'montserratbold',
            letterSpacing: '0.1em',
            textTransform: 'uppercase',
            color: '#FFF',
            padding: '15px 8px 15px 8px',

            [theme.breakpoints.up('md')]: {
                padding: '15px 20px 15px 20px',
            }
        },

        "& td": {
            textAlign: 'left',
            padding: '15px 8px 15px 8px',

            [theme.breakpoints.up('md')]: {
                padding: '15px 20px 15px 20px',
            },
        }
    },
    btn: {
        margin: '26px 0 26px 0',
    },
    mobile: {
        display: 'block',

        [theme.breakpoints.up('md')]: {
            display: 'none',
        },
    },
    desktop: {
        display: 'none',

        [theme.breakpoints.up('md')]: {
            display: 'block',
        },
    },
    logo: {
        width: '112px',
    }
});

class FeesPanel extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
        }
    }

    componentDidMount() {
    }

    render() {
        const { classes } = this.props;
        let self = this;

        return (
            <ThemeProvider theme={muiTheme}>

            <div className={classes.panel}>
                <div className={classes.effect}>
                    <div className={classes.effectLogo}>

                    <div className={classes.content}>
                        <div className={classes.textContent}>
                            <div className={classes.title}>Membership Packages</div>
                            <div className={classes.text}>
                                Our membership packages include annual membership and insurance and offer great value for all our students. Come and see us to discuss which package would work best for you.

                                <table>
                                    <thead>
                                    <tr>
                                        <th></th>
                                        <th>Fortnightly</th>
                                        <th>Monthly</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr>
                                        <th><span className={classes.mobile}>Once per week</span><span className={classes.desktop}>Once per week</span></th>
                                        <td>$40</td>
                                        <td>$71</td>
                                    </tr>
                                    <tr>
                                        <th><span className={classes.mobile}>Unlimited*</span><span className={classes.desktop}>Unlimited*</span></th>
                                        <td>$52</td>
                                        <td>$99</td>
                                    </tr>
                                    <tr>
                                        <th><span className={classes.mobile}>Concessional unlimited*</span><span className={classes.desktop}>Concessional unlimited*</span></th>
                                        <td>$45</td>
                                        <td>$88</td>
                                    </tr>
                                    </tbody>
                                </table>

                                Voucher Lesson book available - 10 x lesson vouchers $198 (valid 12 months from date of purchase) plus an Annual Membership & Insurance fee of $60.

                                <div className={classes.btn}><Link to="/freelesson"><Button variant="contained">Book my free lesson</Button></Link></div>

                                * All adults classes
                            </div>
                        </div>
                    </div>

                    </div>
                </div>
            </div>

            </ThemeProvider>
        )
    }
}

export default withStyles(useStyles)(FeesPanel)