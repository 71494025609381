import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import HeaderPanel from './../../HeaderPanel';
import FooterPanel from './../../FooterPanel';
import SpecialHeroPanel from "./SpecialHeroPanel";
import ContactSpecialPanel from "./ContactSpecialPanel";
import MapPanel from "./../../MapPanel";

const useStyles = theme => ({
    branding: {
        display: 'flex',
        flexWrap: 'wrap',
    },
});

class SpecialPage extends React.Component {
  myRef = React.createRef();

  constructor(props) {
    super(props);
    this.state = {
    };
  }

  componentDidMount() {
    let self = this;
  }

  componentWillUnmount() {
  }

  render() {
    const { classes } = this.props;

    let self = this;

      return (
          <div className={classes.branding}>
              <HeaderPanel showLogo={true} ref={this.myRef} page={'about'} />
              <SpecialHeroPanel />
              <ContactSpecialPanel />
              <MapPanel />
              <FooterPanel />
          </div>
      );
  }
}

export default withStyles(useStyles)(SpecialPage)
