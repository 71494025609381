import React from 'react';
import {withStyles} from "@material-ui/core/styles";
import { ThemeProvider, createTheme } from '@mui/material/styles';
import HeroImage from "../../../assets/instructors/sifutony/24WingChunAcademyLR-10.jpg";
import {Link} from "react-router-dom";

const muiTheme = createTheme({
    palette: {
        primary: {
            main: "#FF2E28",
        },
        secondary: {
            main: "#FFF",
        },
    },
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    fontFamily: "montserratbold",
                    fontSize: '1rem',
                    padding: '10px 28px',
                    borderRadius: '4',
                },
            }
        },
    }
});

const useStyles = theme => ({
    panel: {
        zIndex: '999',
        backgroundColor: '#0D0D0D',
        width: '100%',
        height: '100%',
        minHeight: '100%',
        display: 'flex',
    },
    effect: {
        position: 'relative',
        display: 'flex',
        height: '100%',
        width: '100%',
        alignItems: 'center',
        justifyContent: 'center',
        background: 'linear-gradient(90deg, #614F41, #181A17)',
    },
    panelContent: {
        position: 'relative',
        display: 'flex',
        height: '100%',
        width: '100%',
        alignItems: 'center',
        justifyContent: 'center',
    },
    content: {
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        textAlign: 'center',

        [theme.breakpoints.up('md')]: {
            width: '862px',
        },

        [theme.breakpoints.up('lg')]: {
            flexDirection: 'row',
            width: '1082px',
        }
    },
    photoContent: {
        display: 'flex',
        justifyContent: 'center',
        padding: '48px 36px 0px 36px',

        [theme.breakpoints.up('md')]: {
            padding: '76px 46px 0px 46px',
        },

        [theme.breakpoints.up('lg')]: {
            padding: '76px 0px 64px 50px',
        },
    },
    photo: {
        width: '100%',

        '& img': {
            width: '100%',
        },

        [theme.breakpoints.up('lg')]: {
            width: '482px',
        },
    },
    textContent: {
        textAlign: 'left',
        padding: '16px 16px 26px 16px',

        [theme.breakpoints.up('md')]: {
            padding: '46px 0px 46px 0px',
        },

        [theme.breakpoints.up('lg')]: {
            padding: '64px 0px 64px 66px',
        },
    },
    subtitle: {
        fontFamily: 'montserratbold',
        letterSpacing: '0.1em',
        margin: '12px 0',
        textTransform: 'uppercase',
        color: '#FFF',
    },
    text: {
        margin: '0 0 20px 0',
        color: '#FFF',
    },
    more: {
        margin: '12px 0',

        '& a': {
            padding: '2px 4px',
            fontFamily: 'montserratbold',
            letterSpacing: '0.1em',
            textTransform: 'uppercase',
            color: '#FFF',
            textDecoration: 'none',
            background: 'linear-gradient(0, #614F41 50%, transparent 50%)',
        },
        '& a:hover': {
            background: 'none',
        }
    },
});

class SifuTonyPanel extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
        }
    }

    componentDidMount() {
    }

    render() {
        const { classes } = this.props;
        let self = this;

        return (
            <ThemeProvider theme={muiTheme}>

            <div className={classes.panel}>
                <div className={classes.effect}>

                    <div className={classes.panelContent}>

                    <div className={classes.content}>
                        <div className={classes.photoContent}>
                            <div className={classes.photo}><img src={HeroImage} /></div>
                        </div>

                        <div className={classes.textContent}>
                            <div className={classes.subtitle}>In early 1982 Tony joined the International Wing Chun Academy and was fortunate enough to take private lessons with the Sifu Jim Fung early on in his training </div>
                            <div className={classes.text}>
                                 and continued under the guidance Sifu Jim Fung, Albert Chong (one of Sifu Jim Fung’s first students), Graham Kuerschner (the highest officially ranked Instructor at the time), other senior Instructors.
                                <br /><br />
                                Tony’s life revolved around training and teaching for the school and in 1984, he worked full time at the school as an assistant manager and taught classes at the school’s head office and its various branches. Tony ran workshops and courses for many private and public schools, several TAFE colleges around the Adelaide CBD and metropolitan area. He received high praise and recommendation from the South Australian Education Department for the quality and professionalism he provided to these schools taking up wing chun as part of their physical education curriculum.
                                <br /><br />
                                Although as a teacher and wing chun practitioner he was highly sought after, Tony took a hiatus from wing chun in 1990 to focus on his Christian faith and to go back to study and complete his university degree.   In 1996, he returned and immediately took on a teaching role.  During this time, Tony discovered that his focus has become internally centred and it became apparent to his seniors that he possessed a natural internal ability to relax and maintain this relaxation during technique execution and combat.
                                <br /><br />
                                This ability to maintain a deep state of relaxation is an important component of the wing chun system as taught by Sifu Jim Fung and Sigung Chu Shong Tin and very difficult to achieve.  His ability hadn’t gone unnoticed by his Sifu or by Sigung Chu Shong Tin (who was visiting South Australia from Hong Kong).  Tony was encouraged to further develop this aspect of his training.  He was graded to the 4th level personally by Sigung Chu Shong Tin and was enlisted into the school's circle of chief instructors (which was the school’s highest rank for an instructor). He had been encouraged by Sigung Chu Shong Tin to share his understanding and experience of wing chun with the world.
                                <br /><br />
                                Tony is widely sought after around Australia and to this day gives regular workshops and seminars in SA, NSW and QLD.  He is known for the power he can generate in an explosive, direct, yet effortless manner and the speed and fluidity of his execution. He has been invited to discuss his experience in wing chun (especially internal relaxation and its application) by interested parties in Italy, England, Egypt, Czechoslovakia, Korea and the United States; and hopes that he is able to undertake this in the very near future.
                                <br /><br />
                                In 2013, the International Wing Chun Academy Adelaide branch closed its doors. Two months later in the same year, Tony opened his own school, Adelaide Wing Chun Academy, where he continues to teach, share his passion, and his understanding of wing chun kung fu.
                            </div>

                            <div className={classes.more}><Link to="/sifudamian">Read about Sifu Damian Rainer</Link></div>
                        </div>
                    </div>

                    </div>
                </div>
            </div>

            </ThemeProvider>
        )
    }
}

export default withStyles(useStyles)(SifuTonyPanel)